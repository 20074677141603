import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { NbAuthSimpleToken, NbAuthService } from '@nebular/auth';

@Injectable()
export class ApiService {
    baseUrl: any = 'https://api.soulsface.com/';
    //baseUrl: any = 'http://localhost:23201/';
    token: any = '0';
    headers: any;

    constructor(private http: HttpClient, private authService: NbAuthService) {
        const self = this;
        this.authService.onTokenChange()
          .subscribe((token: NbAuthSimpleToken) => {
              self.setToken(token.toString());
          });
    }

    public async post(endpoint: string, data?: any) {
        const headers = new HttpHeaders({'token': this.token});
        return await this.http.post(this.baseUrl + 'admin/' + endpoint, data, { headers: headers }).toPromise();
    }

    public async removeUser(id: any) {
        const headers = new HttpHeaders({'token': this.token});
        return await this.http.post(this.baseUrl + 'removeOpts/userRemove', { uid: id }, { headers: headers }).toPromise();
    }

    public async get(endpoint: string, data?: any) {
        const headers = new HttpHeaders({'token': this.token});
        return await this.http.get(this.baseUrl + 'admin/' + endpoint, { headers: headers }).toPromise();
    }

    public setToken (token: any) {
        if (token) {
            this.token = token;
        }
    }
}
