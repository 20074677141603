import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
    template: `<div class="addresscell">
        {{address}}
    <br/>
        {{zip}} {{city}}
    </div>`,
})
export class AddressRendererComponent implements ViewCell, OnInit {
    renderValue: string;
    address: string = '';
    city: string = '';
    zip: string = '';

    @Input() value: any;
    @Input() rowData: any;

    constructor() {
    }

    ngOnInit() {
        this.address = this.value.address;
        this.city = this.value.city;
        this.zip = this.value.zip;
        // this.renderValue = this.value.toString().substring(0, 30);
        // tslint:disable-next-line:no-console
        // console.log('row data', this.value, this.rowData);
    }
}
