import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
    template: `<div class="datecell">
      {{start}}
    </div>
    <div *ngIf="hasBothDates" class="datecell">
      {{end}}
    </div>
    `,
})
export class DateRendererComponent implements ViewCell, OnInit {
    renderValue: string;
    hasBothDates: boolean = false;
    start: string;
    end: string;

    @Input() value: any;
    @Input() rowData: any;

    constructor() {
    }

    ngOnInit() {
        // this.renderValue = this.value.toString().toUpperCase();
        // tslint:disable-next-line:no-console
        // console.log('row data', this.value, this.rowData);
        if (Array.isArray(this.value)) {
            this.start = this.parseDate(this.value[0]);
            if (this.value.length > 1) {
                this.hasBothDates = true;
                this.end = this.parseDate(this.value[1]);
            }
        } else {
            this.hasBothDates = false;
            this.start = this.parseDate(this.value);
        }
    }

    parseDate (ddt) {
        try {
            const x = ddt.toISOString();
            return x.split('T')[0].split('-').reverse().join('/');
        } catch (e) {
            return '';
        }
    }

    parseDateTime (ddt) {
        try {
            const x = ddt.toISOString();
            return x.split('T')[0].split('-').reverse().join('/') + x.split('T')[1].split(':').slice(0, 2).join(':');
        } catch (e) {
            return '';
        }
    }
}
