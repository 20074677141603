import { Injectable } from '@angular/core';
import { ApiService } from './Api';

@Injectable()
export class CacheService {
    baseUrl: any = 'https://api.soulsface.com/admin/';
    data: any = [];
    inited: any = false;
    public categories: any = [];
    constructor(private api: ApiService) {
        this.getCategories();
    }

    public async owner(uid) {
        if (!this.inited) {
            // tslint:disable-next-line:no-console
            console.log('no owners data');
            await this.loadOwners();
            // tslint:disable-next-line:no-console
            console.log('now data', this.data);
        }
        for (const x in this.data) {
            if (String(this.data[x]._id) === String(uid)) {
                return this.data[x];
            }
        }
        // tslint:disable-next-line:no-console
        console.log('no user foud', uid);
        return false;
    }

    async loadOwners(owners?: any) {
        if (!owners) {
            owners = await this.remoteUsers();
            // tslint:disable-next-line:no-console
            // console.log('remote', owners);
        }
        const objects = [];
        for (const us of owners) {
            objects.push(us);
        }
        this.data = objects;
        this.inited = true;
    }

    async remoteUsers() {
        const data: any = await this.api.get('users');
        return data.users;
    }

    async getCategories() {
        this.categories = await this.api.get('categories');
    }

}
