import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
    template: `<small class="idRenderer" title="{{value}}">{{renderValue}}</small>`,
})
export class IdRendererComponent implements ViewCell, OnInit {
    renderValue: string;

    @Input() value: string | number;
    @Input() rowData: any;

    constructor() {
    }

    ngOnInit() {
        this.renderValue = this.value.toString().slice(0, 5) + '...';
        // tslint:disable-next-line:no-console
        // console.log('row data', this.value, this.rowData);
    }
}
