import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
    template: `<p class="hiddenLong" title="{{value}}">{{value}}</p>`,
})
export class DescRendererComponent implements ViewCell, OnInit {
    renderValue: string;

    @Input() value: string | number;
    @Input() rowData: any;

    constructor() {
    }

    ngOnInit() {
        // this.renderValue = this.value.toString().substring(0, 30);
        // tslint:disable-next-line:no-console
        // console.log('row data', this.value, this.rowData);
    }
}
