import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
    template: `<div class="statcell">
      <i class="fa fa-eye"></i> {{views}}
    </div>
    <div class="statcell">
      <i class="fa fa-heart"></i> {{likes}}
    </div>
    <div class="statcell">
      <i class="fa fa-heart"></i> {{comments}}
    </div>
    <div style="statcell">
      <i class="fa fa-share"></i> {{shares}}
    </div>
    `,
})
export class StatsRendererComponent implements ViewCell, OnInit {
    renderValue: string;
    views: string;
    likes: string;
    comments: string;
    shares: string;

    @Input() value: any;
    @Input() rowData: any;

    constructor() {
    }

    ngOnInit() {
        // this.renderValue = this.value.toString().toUpperCase();
        // tslint:disable-next-line:no-console
        // console.log('row data', this.value, this.rowData);
        this.views = this.value.views;
        this.likes = this.value.likes;
        this.comments = this.value.comments;
        this.shares = this.value.shares;
    }
}
