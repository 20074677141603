import { Component, Input, OnInit } from '@angular/core';
import { ViewCell } from 'ng2-smart-table';

@Component({
    template: `<a href="{{renderLink}}" target="_blank" class="LinkRenderer" title="">View</a>`,
})
export class LinkRendererComponent implements ViewCell, OnInit {
    renderLink: string;

    @Input() value: string | number;
    @Input() rowData: any;

    constructor() {
    }

    ngOnInit() {
        const ar = this.value.toString().split(':');
        const renderId = ar[0];
        const renderType = ar[1] || 'p';
        this.renderLink = `https://soulsface.com/content/?id=${renderId}&t=${renderType}&locale=it`;
        // tslint:disable-next-line:no-console
        // console.log('row data', this.value, this.renderId, this.renderType, this.rowData);
    }
}
